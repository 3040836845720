<template>
  <!-- 主体内容 -->
  <div class="wrapper">
    <div class="wrap">
      <!-- 商品信息 -->
      <div class="goods-wrap">
        <div class="title">商品列表</div>
        <div>
          <div class="goods">
            <img class="thumb" :src="goodsDetail.imgs[0]" mode="aspectFit" />
            <div class="subtotal">99元 x 1</div>
            <div class="amount">99元</div>
          </div>
        </div>
      </div>
      <!-- 邮费信息 -->
      <div class="express-wrap">
        <div class="name">配送方式</div>
        <div class="value" v-if="expressFee">快递配送</div>
        <div class="value" v-else>货到付款</div>
      </div>
      <!-- 订单小计 -->
      <div class="subtotal-wrap">
        <div class="item">
          <div class="name">商品件数：</div>
          <div class="value">1件</div>
        </div>
        <div class="item">
          <div class="name">商品总价：</div>
          <div class="value">99元</div>
        </div>
        <div class="item">
          <div class="name">活动优惠：</div>
          <div class="value">-0元</div>
        </div>
        <div class="item">
          <div class="name">运费：</div>
          <div class="value">9元</div>
        </div>
        <div class="item">
          <div class="name">应付总额：108</div>
          <div class="value"><span style="font-size: 30px">108</span>元</div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="bottom-wrap">
        <div class="address-wrap">
          <div class="user-info">收货地址</div>
          <div class="user-info">{{ form.name }} {{ form.phone }}</div>
          <div class="address-info">
            <span>{{ form.address }}</span>
            <span class="btn-update" @click="dialogFormVisible = true">修改</span>
          </div>
        </div>
        <div class="btn-wrap">
          <button class="btn btn-primary" @click="submit">立即下单</button>
        </div>
      </div>
    </div>

    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="联系人" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      address: {},
      firstAddress: {},
      goodsDetail: {},
      fixedBar: false,
      expressFee: 0,
      dialogFormVisible: false,
      form: {},
      formLabelWidth:'120px'
    };
  },
  created() {
    let list = JSON.parse(localStorage.getItem("goodsList"));
    let id = this.$route.query.id;
    this.id = id;
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        this.goodsDetail = list[i];
        console.log(this.goodsDetail);
      }
    }
  },
  computed: {},
  methods: {
    submit(){
      if(!this.form.name){
         this.$notify({
          title: '警告',
          message: '请填写收货地址',
          type: 'warning'
        });
        this.dialogFormVisible = true
        return
      }
      this.$message.success('提交成功')
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss">
.header-wrapper {
  height: 100px;
  border-bottom: 2px solid #ff6700;

  .wrap {
    width: 1226px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .title {
      font-size: 28px;
      line-height: 48px;
      color: #424242;
      margin-left: 48px;
    }

    .top-navbar {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #757575;
      font-size: 12px;
    }
  }
}

.wrapper {
  // background-color: #b0b0b0;
  padding: 40px 0 60px;

  .wrap {
    width: 1226px;
    margin: 0 auto;
    background-color: #ffffff;

    .goods-wrap {
      padding: 0 48px;

      .title {
        color: #333;
        font-size: 18px;
        line-height: 40px;
        border-bottom: 1px solid #e0e0e0;
      }

      .goods {
        display: flex;
        align-items: center;
        padding: 15px 0;

        .thumb {
          width: 100px;
          height: 100px;
          margin-right: 10px;
        }

        .name {
          display: inline-block;
          width: 650px;
          color: #424242;
          cursor: pointer;
        }

        .subtotal {
          color: #424242;
          width: 150px;
          text-align: center;
        }

        .amount {
          margin-left: 100px;
          width: 190px;
          text-align: center;
          color: #ff6700;
        }
      }
    }

    .express-wrap {
      border-top: 1px solid #e0e0e0;
      padding: 25px 0;
      margin: 0 48px;
      display: flex;
      align-items: center;

      .name {
        color: #333;
        font-size: 18px;
        width: 150px;
      }

      .value {
        color: #ff6700;
      }
    }

    .subtotal-wrap {
      border-top: 1px solid #e0e0e0;
      padding: 25px 0;
      margin: 0 48px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .item {
        line-height: 2;
        display: flex;
        align-items: center;

        .name {
          color: #757575;
        }

        .value {
          min-width: 100px;
          text-align: right;
          color: #ff6700;
        }
      }
    }

    .bottom-wrap {
      display: flex;
      align-items: center;
      border-top: 2px solid #f5f5f5;
      padding: 25px 48px;

      .address-wrap {
        line-height: 20px;
        color: #424242;

        .btn-update {
          color: #ff6700;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .btn-wrap {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn {
          width: 158px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
          margin: 0;
          margin-left: 30px;

          &.btn-gray {
            border: 1px solid #b0b0b0;
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.27);
          }

          &.btn-primary {
            color: #ffffff;
            border: 1px solid #ff6700;
            background-color: #ff6700;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.fixed-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 70px;
  line-height: 70px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  animation: open 0.5s forwards;

  @keyframes open {
    from {
      margin-top: -50%;
    }

    to {
      margin-top: 0;
    }
  }

  .wrap {
    width: 1226px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .address-wrap {
      color: #757575;
    }

    .btn {
      margin-right: 0;
      width: 160px;
      height: 40px;
      line-height: 40px;
      background-color: #ff6700;
      color: #ffffff;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: #f25807;
      }
    }
  }
}
</style>
